import { IBuyer, ISeller } from '@/interface'
import { Cookie } from './storage'
import dayjs from 'dayjs'
import { useBuyerStore } from '@/stores/modules/buyer'
const TOKEN_KEY = process.env.VUE_APP_TOKEN_KEY as string
const ADDRESS_KEY = process.env.VUE_APP_ACCOUNT_ADDRESS_KEY as string
const BUYER_KEY = process.env.VUE_APP_BUYER_KEY as string
const SELLER_KEY = process.env.VUE_APP_SELLER_KEY as string
const VIP_KEY = process.env.VUE_APP_IS_VIP as string
const LOGIN_DATE_KEY = process.env.VUE_APP_LOGIN_DATE as string

// TOKEN
export const getToken = (): string | null => {
  return Cookie.get(TOKEN_KEY)
}

export const setToken = (v: string, args?: object): void => {
  Cookie.set(TOKEN_KEY, v, args)
}

export const removeToken = () => {
  Cookie.remove(TOKEN_KEY)
}

// Buyer
export const getBuyer = (): IBuyer | null => {
  return Cookie.get(BUYER_KEY) || null
}

export const setBuyer = (v: IBuyer | null, args?: object): void => {
  Cookie.set(BUYER_KEY, v, args)
}

export const removeBuyer = () => {
  Cookie.remove(BUYER_KEY)
}

// address
export const getAddress = (): string => {
  return Cookie.get(ADDRESS_KEY) || ''
}

export const setAddress = (v: string, args?: object): void => {
  Cookie.set(ADDRESS_KEY, v, args)
}
export const removeAddress = () => {
  Cookie.remove(ADDRESS_KEY)
}

// vip
export const getVIP = (): boolean => {
  return Cookie.get(VIP_KEY) || false
}

export const setVIP = (v: boolean, args?: object): void => {
  Cookie.set(VIP_KEY, v, args)
}

export const removeVIP = () => {
  Cookie.remove(VIP_KEY)
}

// 初始化配置
export const setUserInfo = (obj: any, accountAddress: string): void => {
  const { token, info } = obj
  setToken(token)
  setAddress(accountAddress)
  setBuyer(info)
}

export const clearUserInfo = (): void => {
  try {
    // ! 执行两次是因为有些时候会在初始化之前清除数据,但是pinia 无法在初始化之前清除数据.所以执行两次 并且不报出错误
    removeToken()
    removeBuyer()
    removeAddress()
    removeVIP()

    const buyerStore = useBuyerStore()
    buyerStore.CLEAR_BUYER_ALL()
  } catch (error) {
    console.log(error)
  }
}

export const setLoginDate = () => {
  // Cookie.set(LOGIN_DATE_KEY, Date.now())
}

// export const removeLoginDate = () => {
//   Cookie.remove(LOGIN_DATE_KEY)
// }

export const checkUserLoginDate = () => {
  // const date = Cookie.get(LOGIN_DATE_KEY)
  // // 判断是不是超过一天 超过一天清除 登录数据
  // if (date) {
  //   const duration = dayjs(date).diff(dayjs(Date.now()), 'days')
  //   const isMoreThanDay = duration >= 1
  //   if (isMoreThanDay) {
  //     clearUserInfo()
  //   }
  // }
}
