import './components'
import type { I18nMap, SetupProps, WalletLoginProps } from './interface'
export * from './core'
export * from './components/LoadingView'
export * from './interface'

export let coreProps: WalletLoginProps
export let InjectWeb3: any
export let customI18n: I18nMap = {}
export function setup(opt: SetupProps) {
  coreProps = opt.props
  InjectWeb3 = opt.InjectWeb3
  customI18n = opt.i18n || {}
}
