import { WalletLogin } from '@/submodules/WalletLogin'
export * from './ethFunction'
export const shortAddress = (address: string) => {
  if (!address) return
  return address.slice(0, 5) + '...' + address.slice(38, 42)
}
export const numFormat = (num: any) => {
  if (num >= 1000000) {
    num = Math.round(num / 1000) / 10 + 'm'
  } else if (num >= 1000) {
    num = Math.round(num / 100) / 10 + 'k'
  } else {
    num = Math.round(num * 100) / 100
  }
  return num
}

export function getBase64(data: Blob) {
  return new Promise((resolve, reject) => {
    const r = new FileReader()
    r.onload = e => {
      resolve(e?.target?.result)
    }
    r.onerror = () => {
      reject(new Error('image error'))
    }
    r.readAsDataURL(data)
  })
}

export function isImgByType(type: string) {
  const res = type.toLowerCase()
  return res.includes('image')
}

/**
 * @decs 价格格式处理  1,000,000, 默认保留一位小数
 * @returns
 */
export function priceFormat(num: number, digit = 1) {
  const price =
    num.toString().indexOf('.') !== -1
      ? num.toLocaleString('en', { maximumFractionDigits: digit })
      : num.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
  return price
}

interface ISplitArr {
  id: number
  list: any[]
}
export function splitArray(arr: any[], count = 3) {
  const res: ISplitArr[] = []
  for (let i = 0, len = arr.length; i < len; i += count) {
    res.push({
      id: ~~(Math.random() * 10000000),
      list: arr.slice(i, i + count),
    })
  }
  return res
}

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      // eslint-disable-next-line radix
      time = parseInt(time)
    }
    if (typeof time === 'number' && time.toString().length === 10) {
      time *= 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay(),
  }
  const timeStr = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value]
    }
    return value.toString().padStart(2, '0')
  })
  return timeStr
}

// function GetDate(day, type) {
//   const zdate = new Date()
//   let edate
//   if (type === 1) {
//     edate = new Date(zdate.getTime() - day * 24 * 60 * 60 * 1000)
//   } else {
//     edate = new Date(zdate.getTime() + day * 24 * 60 * 60 * 1000)
//   }
//   return edate
// }

// function timeSomeWeek(date) {
//   const date1 = new Date(date) // 将传入的时间字符串转换成时间对象
//   const date2 = new Date() // 当前时间
//   const curWeek = date2.getDay() // 获取当前星期几
//   const monday = GetDate(curWeek, 1) // 计算出星期一
//   const sunday = GetDate(7 - curWeek, 2) // 计算出星期天
//   if (date1.getTime() < monday.getTime() || date1.getTime() > sunday.getTime()) {
//     return false // 不在同一个星期内
//   }
//   return true // 在同一个星期内
// }

export function dateDiff(sDate1, sDate2) {
  let aDate = sDate1.split('-')
  const oDate1: any = new Date(aDate[0], aDate[1], aDate[2]) // 转换为12-18-2006格式
  aDate = sDate2.split('-')
  const oDate2: any = new Date(aDate[0], aDate[1], aDate[2])
  const iDays = parseInt((Math.abs(oDate1 - oDate2) / 1000 / 60 / 60 / 24).toString()) // 把相差的毫秒数转换为天数
  return iDays
}

export function secondDiff(sDate1, sDate2) {
  return parseInt((Math.abs(sDate1 - sDate2) / 1000 / 60).toString())
}

export function parseDate(time) {
  const week = {
    en: {
      1: 'Monday',
      2: 'Tuesday',
      3: 'Wednesday',
      4: 'Thursday',
      5: 'Friday',
      6: 'Saturday',
      0: 'Sunday',
    },
    cn: {
      1: '周一',
      2: '周二',
      3: '周三',
      4: '周四',
      5: '周五',
      6: '周六',
      0: '周日',
    },
  }
  const month = {
    en: {
      '01': 'Jan',
      '02': 'Feb',
      '03': 'Mar',
      '04': 'Apr',
      '05': 'May',
      '06': 'Jun',
      '07': 'Jul',
      '08': 'Aug',
      '09': 'Sept',
      10: 'Oct',
      11: 'Nov',
      12: 'Dec',
    },
    cn: {
      '01': '一月',
      '02': '二月',
      '03': '三月',
      '04': '四月',
      '05': '五月',
      '06': '六月',
      '07': '七月',
      '08': '八月',
      '09': '九月',
      10: '十月',
      11: '十一月',
      12: '十二月',
    },
  }

  if (parseTime(new Date(), '{y}-{m}-{d}') === parseTime(time, '{y}-{m}-{d}')) {
    return parseTime(time, '{h}:{i}')
  }
  if (dateDiff(parseTime(new Date(), '{y}-{m}-{d}'), parseTime(time, '{y}-{m}-{d}')) === 1) {
    return `Yestoday ${parseTime(time, '{h}:{i}')}`
  }
  if (dateDiff(parseTime(new Date(), '{y}-{m}-{d}'), parseTime(time, '{y}-{m}-{d}')) <= 7) {
    return `${week.en[new Date(time).getDay()]}`
    //  ${parseTime(time, '{h}:{i}')}
  }
  return `${month.en[parseTime(time, '{m}')]} ${parseTime(time, '{d},{y}')}`
}

/**
 *
 * 处理图片url
 */
export const webpImageHandle = (url: string, type: number) => {
  //处理空值
  if (url === null || url?.length == 0 || url === undefined) {
    return url
  }

  const TYPE = {
    1: '@style@webp', // 无宽限制
    2: '@style@cover', // 限宽
  }
  const GIF_TYPE = {
    // 1: '@style@gif',
    // 2: '@style@gifcover',
    // gif暂时先不缩图，因为有大于10m的情况，目前金山云就能处理10m
    1: '',
    2: '',
  }
  const isGif = url.toUpperCase().indexOf('.GIF') > -1 ? true : false
  if (url.indexOf('image.slash.sg') > -1) {
    return url + (isGif ? GIF_TYPE[type] : TYPE[type])
  } else {
    return url
  }
}
/**
 * @decs 处理富文本中 图片地址
 * @param 富文本内容
 */
export const replaceImg = (content: any) => {
  if (!content) return
  // TODO 这里正则替换的有点儿不太合适，但勉强能用
  const imgObj = /<img [^>]*src=['"]([^'"]+)[^>]*>/g
  const imgArr = content.match(imgObj)
  if (imgArr) {
    for (let i = 0; i < imgArr.length; i++) {
      const srcImg = imgArr[i].replace(imgObj, '$1')
      if (srcImg.indexOf('image.slash.sg') > -1) {
        const newItem = imgArr[i].replace(
          /src=['"]([^'"]+)['"]([^>]*)>/gi,
          'src="' + webpImageHandle(srcImg, 1) + '"' + '$2>',
        )
        content = content.replace(imgArr[i], newItem)
      }
    }
  }

  return content
}

/**
 * @decs 秒转换时分秒
 * @param 秒
 */
export const timeHandle = (time: number) => {
  if (time === 0) return '00:00'
  const obj: any = {
    H: null,
  }
  const hour = Number.parseInt('' + time / 3600)
  let min: string | number = Number.parseInt('' + (time - hour * 3600) / 60)
  let seconds: string | number = Number.parseInt('' + (time - hour * 3600 - min * 60))

  if (min < 10) {
    min = '0' + min
  }
  if (seconds < 10) {
    seconds = '0' + seconds
  }
  console.log()
  return `${min}:${seconds}`
}

export function getFullNum(num) {
  //处理非数字
  if (isNaN(num)) {
    return num
  }

  //处理不需要转换的数字
  const str = '' + num
  if (!/e/i.test(str)) {
    return num
  }

  return num.toFixed(18).replace(/\.?0+$/, '')
}
export async function checkChain(allowRpcIdList: any): Promise<any> {
  const currentWeb3 = WalletLogin.getInstance().web3Instance
  const currentChainId = await currentWeb3?.eth.getChainId()
  const appointChain = allowRpcIdList || WalletLogin?.CHAINS_LIST_BY_PARTICLE.ArbitrumOne.id
  const bol = appointChain.includes(currentChainId)
  console.log(allowRpcIdList, 'checkChain---->', appointChain, currentChainId, bol)
  return {
    bol,
    chainId: appointChain[0],
  }
}
export async function switchRightChain(id: any) {
  const target = Object.values(WalletLogin.CHAINS_LIST_BY_PARTICLE).find((t: any) => t.id === id)
  try {
    console.log(target, 'target')
    await WalletLogin.getInstance().particleInstance.switchChain(target! as any)
  } catch (error: any) {
    return Promise.reject(error?.message || error)
  }
}
