import "core-js/modules/es.array.includes.js";
import { walletService, userService } from '@/api';
import { setup } from '@/submodules/WalletLogin';
import { ElMessage, ElNotification } from 'element-plus';
import { clearUserInfo, setToken, setUserInfo, setLoginDate } from '@/utils/auth';
import Web3 from 'web3';
import { isGLDB, isQTUM } from '@/config';

function asyncUserInfo(info, buyer, userCode) {
  return new Promise((resolve, reject) => {
    if (info && Object.keys(info).length > 0 && (!buyer.pic || !buyer.userName)) {
      const {
        email,
        name: userName,
        picture: pic
      } = info; // console.log("==============>", info)

      const body = {
        userCode
      };

      if (!buyer.email) {
        body.email = email;
      }

      if (!buyer.userName) {
        body.userName = userName;
      }

      if (!buyer.pic) {
        body.pic = pic;
      }

      if (Object.keys(body).length === 1) {
        resolve({});
        return;
      }

      userService.updateProfile(body).then(() => {
        resolve(body);
      }).catch(err => {
        reject(err);
      });
    } else {
      resolve({});
    }
  });
}

const isDev = !['production'].includes(process.env.NODE_ENV);
const props = {
  isDev,
  isShowSocialConnect: !isGLDB() && !isQTUM(),
  isShowWallet: !isQTUM(),
  telWhitelist: isQTUM() ? ['86'] : [],
  loginConfig: {
    getRandom: walletService.getRadomNoByAddress,
    getSignName: () => {
      const sellerStore = useSellerStore();
      return sellerStore.shop?.displayName || '';
    },
    login: async (context, connectReturn) => {
      const address = context.address;
      const signature = connectReturn.signature;
      const loginBody = {
        address,
        signature,
        type: 0,
        ...(connectReturn.signByParticle || {})
      };
      const loginRes = await walletService.login(loginBody);
      setToken(loginRes.token);
      const {
        info: {
          userCode
        }
      } = loginRes;
      setUserInfo(loginRes, address);
      const buyer = await userService.getSeller(userCode);
      const newUserInfo = await asyncUserInfo(connectReturn?.userInfo, buyer, userCode);
      const buyerStore = useBuyerStore();
      buyerStore.SET_TOKEN(loginRes.token);
      buyerStore.SET_ADDRESS(address);
      buyerStore.SET_BUYER({ ...loginRes.info,
        userName: newUserInfo.userName || buyer.userName,
        pic: newUserInfo.pic || buyer.pic,
        email: newUserInfo.email || buyer.email
      });
      await buyerStore.GET_VIP();
      setLoginDate();
    }
  },
  logout: () => {
    clearUserInfo();
  },
  isAutoConnectByCache: async () => {
    const store = await import('@/stores/modules/buyer');
    const buyerStore = store.useBuyerStore();
    return Promise.resolve(buyerStore.isLogin);
  },
  getI18nLocale: () => {
    const lang = localStorage.getItem('locale');
    const obj = {
      zh_CN: 'cn',
      en_US: 'en'
    };
    return lang ? obj[lang] : 'en';
  },
  on: {
    disconnect: () => {
      clearUserInfo();
    },
    chainChanged: (message, networkId) => {
      ElMessage({
        type: 'info',
        message: 'Switch Chain...'
      });
      ElNotification({
        title: 'Warning',
        duration: 0,
        showClose: false,
        message,
        type: 'warning'
      });
    },
    error: e => {
      ElMessage({
        type: 'error',
        message: e?.msg || e?.message || e
      });
    }
  }
};
setup({
  props,
  InjectWeb3: Web3
});