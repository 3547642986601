import { WalletLogin } from '@/submodules/WalletLogin'
// https://docs.particle.network/auth-service/sdks/web#open-crypto-token-buy

export function buyEth(
  opt = {
    cryptAmt: 0,
  },
) {
  const instance = WalletLogin.getInstance()
  const { cryptAmt } = opt
  const config: any = {
    network: 'Ethereum',
    walletAddress: instance.address,
    cryptoCoin: 'ETH',
  }
  if (cryptAmt) {
    config.cryptAmt = (cryptAmt + 0.01).toFixed(2)
  }
  instance.openBuy(config)
}
